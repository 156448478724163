import React, { Component } from 'react';
import SessionContext from '../SessionContext'

class TopBar extends Component {

    static contextType = SessionContext
  
    render() {
        let title = "Backoffice"
        if (this.context.store !== undefined) {
            if (this.context.branch !== undefined) {
                title = `${this.context.store.name} - ${this.context.branch.name}`
            } else {
                title = `${this.context.store.name}`
            }
        }
        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <h2 className='pl-3'>{title}</h2>
            </nav>
        );
    }
}
export default TopBar;